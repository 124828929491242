import * as React from "react"

export default function GitHubIcon(props) {
  return (
    <svg
      width={33}
      height={33}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 1a16 16 0 00-5.058 31.185c.795.147 1.09-.354 1.09-.776v-2.72c-4.45.981-5.392-2.142-5.392-2.142a4.282 4.282 0 00-1.778-2.348c-1.444-.982.118-.982.118-.982a3.369 3.369 0 012.445 1.65 3.418 3.418 0 004.656 1.336c.062-.808.41-1.567.982-2.141-3.555-.403-7.288-1.778-7.288-7.907a6.188 6.188 0 011.64-4.292 5.824 5.824 0 01.158-4.233s1.345-.432 4.4 1.64c2.623-.72 5.392-.72 8.015 0 3.054-2.072 4.39-1.64 4.39-1.64a5.795 5.795 0 01.167 4.233 6.188 6.188 0 011.64 4.292c0 6.149-3.742 7.494-7.307 7.858a3.781 3.781 0 011.09 2.946v4.39c0 .521.285.924 1.1.777A16.01 16.01 0 0017 .94V1z"
        fill="currentColor"
      />
    </svg>
  )
}
